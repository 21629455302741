import React from 'react';
import styled, {css} from 'styled-components';
import {colors, devices} from '../../../styles/theme';
import {Button, H5, TextContainer, Link} from '../../atoms';
import Img from 'gatsby-image';

const Container = styled.div`
  grid-column: span 2;
  margin: 24px 0;
  @media ${devices.tabletLandscape} {
    margin: 40px 0;
  }
`;

const thumbnailStyle = css`
  height: 224px;
`;

const ThumbNail = styled(Img)`${thumbnailStyle}`;
const NativeThumbnail = styled.img`${thumbnailStyle}`;

const ProjectInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  margin: 16px;
`;

const Title = styled(H5)`
  color: ${colors.blue};
`;

const ProjectPreview = ({project, buttonText}) => {
  const {
    image,
    title,
    slug,
  } = project;

  return (
    <Container>
      <TextContainer>
        <Link to={`/projects${slug}`}>
          {image?.file?.extension === 'svg'
            ? <NativeThumbnail
              src={image?.file?.publicURL}
              alt={image?.alternativeText}
            />
            : <ThumbNail
              fluid={image?.file?.childImageSharp?.fluid}
              alt={image?.alternativeText}
            />
          }
        </Link>
        <ProjectInfo>
          <Link to={`/projects${slug}`} style={{textDecoration: 'none'}}>
            <Title>{title}</Title>
          </Link>
          <Link to={`/projects${slug}`}>
            <Button type="secondary">
              {buttonText || 'Read more'}
            </Button>
          </Link>
        </ProjectInfo>
      </TextContainer>
    </Container>
  );
};

export default ProjectPreview;
