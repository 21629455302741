import {graphql, useStaticQuery} from 'gatsby';

const useProjectsOverviewData = () => {
  const data = useStaticQuery(graphql`
    query {
      strapi {
        projectsOverview {
          SEO {
            ...SEO
          }
          header {
            ...RegularHeader
          }
          intro {
            ...Intro
          }
          contact {
            ...ContactUs
          }
          buttonText
        }
        projects {
          ...Project
        }
      }
    }
  `);

  return data.strapi;
};

export default useProjectsOverviewData;
